<template>
    <div class="video-list-bg">
         <div class="head-boc">
          <span class="iconfont icon-arrow-left-bold" @click="onClickLeft"></span>
         <van-search class="head-search" v-model="keywords" placeholder="请输入搜索关键词" />
        </div>
        <div class="list-item" v-for="item in search(keywords)" :key="item.id" @click="onPlayVideo(item.split('|')[1])">
            <div class="list-icon iconfont icon-video-file_fill1"></div>
            <div class="list-right">
                <div class="list-text">{{item.split("|")[0]}}</div>
                <div class="list-btn">播放</div>
            </div>
        </div>
        <van-empty  image="network" v-show="search(keywords).length<1?true:false" description="暂无搜索内容" />
    </div>
</template>
<script>

export default {
  data() {
    return {
      value:"",
      keywords:"",
      imgList: []
    };
  },
  methods: {
    onPlayVideo(videoUrl) {
      this.$router.push({name:'playVideo',query: {videoUrl:videoUrl}})
    },
    search(keywords) { // 根据关键字，进行数据的搜索
      return this.imgList.filter(item => {
        if (item.split("|")[0].includes(keywords)) {
        return item
        }
      })
    },
    onClickLeft() {
      this.$router.push('/video') ;
    }
  },
  created() {    
  	this.imgList = this.$route.params.userId.replace(/^\"|\"$/g, '').split(/,/);
  }
};

</script>
<style lang="less" scoped>
    .head-boc {
      	display: -webkit-flex;
      	display: flex;
        -webkit-align-items: center;
	      align-items: center;
        padding: 0 15px;
        .iconfont {
          font-size: 40px;
        }
        .head-search {
            -webkit-flex: 1;
            flex: 1;
        }
    }
    .video-list-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        background-color: #fff;
    }
    .list-item  {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 20px;
        .list-icon {
            font-size: 40px;
            color: #8687FF;
            margin-right: 20px;
        }
        .list-right {
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            font-size: 28px;
            -webkit-flex: 1;
            flex: 1;
            border-bottom: 1px solid #EFEFEF;
            padding: 20px 0;
            .list-text {
                 -webkit-flex: 1;
                 flex: 1;
                 color: #333;
            }
            .list-btn {
                width: 100px;
                height: 60px;
                line-height: 60px;
                background: #FFFFFF;
                border: 1px solid #EFEFEF;
                border-radius: 25px;
                text-align: center;
                color: #666666;
            }
        }
    }
    
</style>